import React, { Component, Fragment } from 'react'
import Section from '../../section'
import Tracker from '../../gtm/event-tracker'
import Gallery from './gallery'
import content from './content'
import './index.css'

export default class SaferSystemsExperience extends Component {
  render () {
    const items = content.prepare(this.props.content, this.props.outreach)
    const showItems = items && items.length > 0
    const { cta } = this.props.links
    const introCopy = showItems ? 'Continued improvements to our road environment make it safer for all who use it. Here are some of the improvements you suggested, and how they scored for safety.' : 'Continued improvements to our road environment make it safer for all who use it.'
    return (<Section id={this.props.id} theme='blue' useCircle>
      <div className='exp-safer-systems tl tc-ns flex flex-column items-center-ns ph4 ph6-m ph5-l ph0-ns'>

        <h1 className='heading1 lh-solid mb2'>DESIGNING <br className='db dn-ns' />A&nbsp;SAFE<br className='db' />ENVIRONMENT</h1>

        <p className='info fos f6 f5-ns lh-copy mt4 mb4 ph0 ph5-l'>{introCopy}</p>

        <Tracker value='safe-environment-cta-click'>
          <a href={cta.url} target='_blank' className='cta tbutton clear white flex mt3 mb4 mb6-l'>{cta.label}</a>
        </Tracker>

        { showItems
          ? <Fragment>
            <Gallery items={items} />
            <p className='dn db-l fos ph4 ph0-ns mt4 i'>SELECT TO VIEW A ROAD SYSTEM</p>
          </Fragment>
          : null }

      </div>
    </Section>
    )
  }
}
