import React, { Component } from 'react'
import { connect } from 'react-redux'
import img404 from '../../assets/404.png'
import Logo from '../../assets/logo-intro.svg'
import './style.css'

class Error extends Component {
  render () {
    return (
      <div className='error flex items-center'>
        <div className='content w-100 pa4 tc flex flex-column justify-center'>
          <div className='pa0'><img src={Logo} /></div>
          <div className='pt5 pt5-l'><img src={img404} /></div>
          <div className='pt2 pt5-l lh-copy'>The page you're looking for does not exist.</div>
          <div className='pt5 pt5-l'>
            <a className='cta tbutton clear white flex' href='https://www.roadtozero.vic.gov.au'>BACK TO HOMEPAGE</a>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Error)
